import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import LayoutPictures from "../../components/layout/layout-pictures"
import TitledContentWrapper from "../../components/layout/titled-content-wrapper"
import { PicturesAward } from "../../components/pictures/pictures-award"
import { PicturesBoard } from "../../components/pictures/pictures-board"

const AwardsPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        picturesAwardsPage {
          Title
        }
        awards {
          RewarderName
          RewarderLogo {
            url
            imageFile {
              childImageSharp {
                fixed(width: 280) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          Award {
            AwardTitle
            AwardLead
            AwardYear
            AwardWork {
              id
            }
            AwardImage {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const logos = data.strapi.awards.map(({ RewarderName, RewarderLogo }) => ({
    Name: RewarderName,
    Logo: RewarderLogo,
  }))

  return (
    <LayoutPictures>
      <TitledContentWrapper
        title={data.strapi.picturesAwardsPage.Title}
        maxWidth="1280px"
      >
        <PicturesBoard logos={logos} />
      </TitledContentWrapper>
      <PicturesAward awards={data.strapi.awards} />
    </LayoutPictures>
  )
}

export default AwardsPage
