import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Img from "gatsby-image"
import ScrollTo from "../utils/scroll-to"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "50px",
    maxWidth: "1280px",

    [theme.breakpoints.down("sm")]: {},
  },
  logos: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingBottom: "50px",
    margin: "auto",
  },
  images: {
    width: "280px",
    height: "200px",
    textAlign: "center",
    margin: "16px",
    border: "1px solid #343333",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "140px",
      marginTop: "0px",
    },
    "&:hover img": {
      filter: "brightness(130%)",
    },
    "&": {
      transition: "all 800ms ease !important",
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}))

export const PicturesBoard = ({ logos }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.logos}>
        {logos.map(({ Logo, Name }) => (
          <ScrollTo key={Name} className={classes.images} to={Name}>
            <Img fixed={Logo.imageFile.childImageSharp.fixed} alt={Name} />
          </ScrollTo>
        ))}
      </Box>
    </Box>
  )
}
