import React from "react"

function Cup() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
      style={{ marginBottom: "-4px" }}
    >
      <path
        fill="#F26522"
        d="M23.086 9.867c-.8.827-1.786 1.334-2.96 1.52-.586 3.574-3.093 5.387-6.026 5.76v3.92h2.293c.587 0 1.067.48 1.067 1.067a1.07 1.07 0 01-1.067 1.067H9.7a1.07 1.07 0 01-1.067-1.067c0-.587.48-1.067 1.067-1.067h2.266v-3.92c-2.933-.373-5.44-2.186-6.026-5.76a4.835 4.835 0 01-2.96-1.52C.793 7.601.9 3.734.926 3.574a.94.94 0 01.934-.907h4v-.8c0-.586.453-1.066 1.04-1.066h12.32c.586 0 1.04.48 1.04 1.066v.8h4c.506 0 .906.4.933.907-.053.16.053 4.053-2.107 6.293zm-18.8-1.28c.427.427.987.747 1.52.88V4.534h-3.04c.107 1.067.427 2.907 1.52 4.053zm13.787-5.653H7.94v6.907c0 3.866 2.613 5.226 5.066 5.226 2.454 0 5.067-1.386 5.067-5.226V2.934zm3.653 5.653c1.04-1.066 1.414-2.72 1.52-4.053h-3.04v4.933c.534-.16 1.094-.453 1.52-.88z"
      ></path>
    </svg>
  )
}

export default Cup
