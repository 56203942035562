import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"
import { DigicLink } from "../digic-link"
import Cup from "./cup"

const useStyles = makeStyles(theme => ({
  container: {
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
      maxWidth: "initial",
      width: "100%",
    },
    "& img": {
      transition: "all 800ms ease !important",
    },
    "&:hover img": {
      transform: "scale(1.1)",
    },
    minWidth: "45%",
    maxWidth: "45%",
    display: "flex",
  },
  layerBottom: {
    width: "100%",
    height: "100%",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100px",
    },
  },
  image: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "40vw",
    },
  },
  layerTop: {
    position: "relative",
    gridRow: 1,
    [theme.breakpoints.down("sm")]: {
      gridRow: 2,
    },
    gridColumn: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    minHeight: "200px",
  },
}))

export const PicturesAwardCard = ({
  AwardTitle,
  AwardLead,
  AwardYear,
  AwardImage,
  WorkLink,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <DigicLink to={WorkLink}>
        <Box className={classes.layerTop}>
          <Box className={classes.layerBottom}>
            <Img
              fluid={AwardImage.imageFile.childImageSharp.fluid}
              alt={AwardTitle}
            />
          </Box>
          <Box className={classes.title}>
            <DigicLink to={WorkLink}>
              <Typography
                variant="h3"
                style={{
                  fontWeight: "700",
                  maxWidth: "725px",
                  color: "#F26522",
                  fontSize: "24px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Cup /> {AwardTitle}
              </Typography>
            </DigicLink>
            <Typography
              style={{
                marginBottom: "20px",
              }}
            >
              {AwardLead}
            </Typography>
            <Typography
              style={{
                color: "#676666",
                fontWeight: "700",
              }}
            >
              {AwardYear}
            </Typography>
          </Box>
        </Box>
      </DigicLink>
    </Box>
  )
}
