import { Box, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"
import { PicturesAwardCard } from "./pictures-award-card"

const useStyles = makeStyles(theme => ({
  logo: {
    paddingTop: "80px",
    maxWidth: "295px",
    marginLeft: "25px",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#D5810F",
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": {
      color: "#FFF",
    },
  },
}))

export const PicturesAward = ({ awards }) => {
  const classes = useStyles()

  return (
    <Container>
      {awards.map(({ RewarderName, RewarderLogo, Award }) => (
        <Container id={RewarderName}>
          <Box className={classes.logo}>
            <Img
              fixed={RewarderLogo.imageFile.childImageSharp.fixed}
              alt={RewarderName}
            />
          </Box>
          <Box className={classes.container}>
            {Award.map(
              ({ AwardTitle, AwardLead, AwardYear, AwardImage, AwardWork }) => (
                <PicturesAwardCard
                  AwardTitle={AwardTitle}
                  AwardLead={AwardLead}
                  AwardYear={AwardYear}
                  AwardImage={AwardImage}
                  WorkLink={`/pictures/works/${AwardWork?.id}`}
                />
              )
            )}
          </Box>
        </Container>
      ))}
    </Container>
  )
}
